import React from 'react';
import { useRouter } from 'next/router';
import { QueryClient, useQuery } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import { withTheme } from 'styled-components';
import {
	removeQueryString,
	RegistryComponent,
	capitalizeFirstCharacter,
} from 'shared/helpers';

import {
	noHeaderVariants,
	informationPageTypeVariants,
	councilPageTypeVariants,
} from 'shared/consts';

import { LayoutContainer } from 'layout';

import { getPageByUrl, getMetadataByUrl } from 'api';
import { Loader } from 'shared/components';
import Subject from 'shared/pages/Subject/Subject';

const Page = ({ metaData = {}, slug = '', initData }) => {
	const { asPath } = useRouter();
	const urlToGet = removeQueryString(asPath);
	const { data: page, isLoading } = useQuery(
		urlToGet,
		() => getPageByUrl(urlToGet),
		{ initialData: initData },
	);

	const isInformationPage = !!Object.values(
		informationPageTypeVariants,
	).includes(page?.Alias);

	const isCouncilPage = !!Object.values(councilPageTypeVariants).includes(
		page?.Alias,
	);

	const alias = isInformationPage
		? informationPageTypeVariants.INFORMATIONPAGE
		: isCouncilPage
			? councilPageTypeVariants.COUNCILPAGE
			: page?.Alias;

	const noHeader =
		page?.Alias &&
		!!Object.values(noHeaderVariants).find((x) => x === page?.Alias);

	return (
		<LayoutContainer noHeader={noHeader} {...metaData} slug={slug}>
			{isLoading ? (
				<Loader />
			) : (
				<RegistryComponent
					alias={capitalizeFirstCharacter(alias)}
					{...page}
				/>
			)}
		</LayoutContainer>
	);
};

export async function getStaticProps(ctx) {
	const queryClient = new QueryClient();
	let url = '/';
	if (Object.keys(ctx.params).length > 0)
		url = `/${ctx.params.url.join('/')}`;

	const data = await getPageByUrl(removeQueryString(url));
	/* Ugly method, but it allows for true 404 error handling */
	if (data === 404 || data === Subject) return { notFound: true };
	if (data === 500) throw new Error('500-fel');
	if (data === 406)
		throw new Error(
			`Got "${data} Not Acceptable" when fetching data from ${url}`,
		);
	if (data >= 400)
		throw new Error(`Got ${data} when fetching data from ${url}`);

	if (data?.redirect) {
		return {
			redirect: {
				destination: data.redirectUrl,
				statusCode: 301,
			},
		};
	}

	const metaData = await getMetadataByUrl(url);
	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			metaData,
			slug: url,
			initData: data,
		},
		revalidate: 10,
	};
}

/* If fallback: 'blocking' is removed, 404 will no longer work */
export async function getStaticPaths() {
	return { paths: [], fallback: 'blocking' };
}

export default withTheme(Page);
